<template>
  <div style="display: flex;flex-direction: column;overflow-y: auto;height: 100%;padding-left: 10px;padding-right: 10px;">
    <div v-for="persentItem in persentMortarData" :key="persentItem.id" :index="persentItem.id">
      <div style="margin-top: 5px;margin-bottom:5px;display: flex;flex-direction: column;border:  1px solid;background: #eeeeee" :style="{borderColor:$store.state.settings.theme}" >
        <div style="display: flex;height: 35px;align-items: center;padding-left: 10px;filter:opacity(0.7)" :style="{backgroundColor:$store.state.settings.theme}">
          <div style="flex: 1;display: flex">
            <span style="font-weight: bold;font-size: 14px;color: white">{{persentItem.name}}</span>
          </div>
          <el-button style="margin-right: 5px" size="mini" type="primary" round >总计({{persentItem.children.length}}) </el-button>
        </div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;font-size: 13px">
          <div class="card-content" :index="mortar.busID" v-for="mortar in persentItem.children" :key="mortar.busID" @click="showMortarInfoInparent(mortar)">
            <div>
              {{mortar.persent}}%
            </div>
            <div :style="{backgroundImage:'url('+mortar.bigIcon+')'}"
                 style="background-size: cover;height: 66px;width: 45px;display: flex;flex-direction: column;justify-content: flex-end; align-items: center;cursor: pointer ">
              <div style="margin-bottom: 30px">
                {{mortar.weight}}
              </div>
            </div>
            <div>
              {{mortar.potNo}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="cproject.projectName"
      :visible.sync="projectDialog"
      :append-to-body="true"
      v-dialogDrag
      width="750px">
      <div style="display: flex;flex-direction: row;flex-wrap: wrap;font-size: 13px;border-bottom: 1px solid #D3D3D3">
        <div style="margin: 5px;padding: 5px 16px;display: flex;flex-direction: column;align-items: center;justify-content: center;" :index="mortar.busID" v-for="mortar in cproject.children"
             :key="mortar.busID" @click="showMortarInfoInparent(mortar)">
          <div>
            {{mortar.persent}}%
          </div>
          <div :style="{backgroundImage:'url('+mortar.bigIcon+')'}" style="background-size: cover;height: 95px;width: 65px;display: flex;flex-direction: column;justify-content: flex-end; align-items: center;cursor: pointer ">
            <div style="margin-bottom: 30px">
              {{mortar.weight}}
            </div>
          </div>
          <div>
            {{mortar.potNo}}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="projectDialog = false" size="mini">关闭</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import XEUtils from 'xe-utils'
import { addMessageLisner } from '@/utils/websocket'
import { getPotTree0 } from '@/apis/monitor/mortarMonitor'
import { formatWebsocketData } from './util'

export default {
  name: 'MortarByPersent',
  props: {
    showMortarInfo: { type: Function, default: null }
  },
  data () {
    return {
      persentMortarData: [],
      mortarMap: new Map(),
      cproject: {},
      projectDialog: false
    }
  },
  created () {
    // this.$websocket.addMessageLisner(24, this.onDriverMessage)
    for (let i = 0; i < 10; i++) {
      this.persentMortarData.push({
        id: i,
        name: '(' + i * 10 + '-' + (i + 1) * 10 + ')%',
        children: []
      })
    }
  },
  beforeDestroy () {
    // this.$websocket.removeMessageLisner(24, this.onDriverMessage)
  },
  async mounted () {
    await this.getPotData()
    // 接口请求回来3s之后 开始推送
    setTimeout(() => {
      addMessageLisner(25, this.onWsMessage)
    }, 3000)
  },
  methods: {
    showProjectDialog (project) {
      this.cproject = project
      this.projectDialog = true
    },
    onWsMessage (data) {
      data.forEach(item => {
        const newNode = formatWebsocketData(item)
        this.onDriverMessage(newNode)
      })
    },
    async getPotData (cancel, cache) {
      getPotTree0({ key: 'DEV', parentKey: 'PROJECT' }, cancel, cache).then((res) => {
        if (res.code === 200) {
          const mortarData = XEUtils.map(res.data.rtmTreeList.treeList, (item) => item.detail)
          mortarData.forEach((mortar) => {
            if (mortar.weight == '' || mortar.weight == null) {
              mortar.weight = 0
            }
            if (mortar.maxWeig === 0) {
              mortar.maxWeig = 40
            }
            let persent = mortar.weight * 100 / mortar.maxWeig
            persent = persent.toFixed(0)
            if (persent > 100) {
              persent = 100
            }
            if (persent < 0) {
              persent = 0
            }
            let persentImg = persent
            if (persentImg < 2) {
              persentImg = 2
            }
            if (persentImg % 2 === 1) {
              persentImg = persentImg - 1
            }
            mortar.bigIcon = require('@/assets/imgs/mortar/big/gray/' + persentImg + '.png')
            mortar.persent = persent
            mortar.isOnlineStr = '离线'
            mortar.maxWeight = mortar.maxWeig
            let index = Math.floor(persent / 10)
            if (index === 10) {
              index = 9
            }
            if (isNaN(index)) {
              index = 0
            }
            if (this.persentMortarData[index]) {
              this.persentMortarData[index].children.push(mortar)
            }
            this.mortarMap.set(mortar.busID, mortar)
          })
        }
      })
    },
    onDriverMessage (msg) {
      const mortar = this.mortarMap.get(msg.busID)
      if (mortar) {
        if (msg.weight == '' || msg.weight == null) {
          msg.weight = 0
        }
        let persent = parseFloat(msg.weight) * 100 / parseFloat(mortar.maxWeight)
        persent = persent.toFixed(0)
        if (persent > 100) {
          persent = 100
        }
        if (persent < 0) {
          persent = 0
        }
        let indexOld = Math.floor(mortar.persent / 10)
        let indexNew = Math.floor(persent / 10)

        if (indexOld === 10) {
          indexOld = 9
        }
        if (indexNew === 10) {
          indexNew = 9
        }
        if (isNaN(indexOld)) {
          indexOld = 0
        }
        if (indexNew !== indexOld) {
          const index = this.persentMortarData[indexOld].children.findIndex((item) => {
            return item.busID === mortar.busID
          })
          if (index !== -1) {
            this.persentMortarData[indexOld].children.splice(index, 1)
          }
          this.persentMortarData[indexNew].children.push(mortar)
        }
        let persentImg = persent
        if (persentImg < 2) {
          persentImg = 2
        }
        if (persentImg % 2 === 1) {
          persentImg = persentImg - 1
        }
        mortar.bigIcon = require('@/assets/imgs/mortar/big/blue/' + persentImg + '.png')
        mortar.persent = persent
        mortar.isOnlineStr = '在线'
        Object.assign(mortar, msg)
      }
    },
    /* onMortarOffline (msg) {
      const mortar = this.mortarMap.get(msg.busID)
      if (mortar) {
        let persentImg = mortar.persent
        if (persentImg < 2) {
          persentImg = 2
        }
        if (persentImg % 2 === 1) {
          persentImg = persentImg - 1
        }
        mortar.bigIcon = '@/assets/imgs/mortar/big/gray/' + persentImg + '.png'
        mortar.isOnlineStr = '离线'
      }
    }, */
    showMortarInfoInparent (mortar) {
      if (this.showMortarInfo) {
        this.showMortarInfo(mortar)
      }
    }
  }
}
</script>

<style scoped>
.card-content {
  margin: 5px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}
</style>
