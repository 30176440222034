<template>
  <wz-table
    ref="tableRef"
    :data="data"
    auto-resize
    height="auto"
    v-bind="$attrs"
    v-on="$listeners"
    show-overflow
    :sort-config="{trigger: 'cell', defaultSort: {field: 'time', order: 'desc'}, orders: ['desc', 'asc', null]}"
  >
    <vxe-column type="seq" title="序号" width="60" />
    <vxe-column field="potNo" title="罐编号" minWidth="100" sortable/>
    <!--    <vxe-column field="busLicPlate" title="名称" show-overflow="title" min-width="100" show-header-overflow="title" sortable/>-->
    <vxe-column title="图标" minWidth="80">
      <template #default="{ row }">
        <div class="flex align-center justify-center">
          <img
            v-if="row.markerIcon"
            :src="row.markerIcon"
            style="width: 20px; height: 28px;cursor: pointer "
          />
        </div>
      </template>
    </vxe-column>
    <vxe-column field="weight" title="余量(吨)" minWidth="100" :sortable="true" />
    <vxe-column field="persent" title="剩余百分比" minWidth="135" :sortable="true"/>
    <vxe-column field="maxWeight" title="最大重量(吨)" minWidth="110" :sortable="true" />
    <!--    <vxe-column field="potType" title="砂浆类型" minWidth="135" :sortable="true"/>-->
    <vxe-column field="lastFixDatTim" title="时间" minWidth="135" :sortable="true"/>
    <vxe-column field="isOnline" title="状态" minWidth="80">
      <template #default="{ row }">
        <span :class="{green: row.isOnline}">{{row.isOnlineStr}}</span>
      </template>
    </vxe-column>
    <vxe-column field="lat" title="纬度" show-overflow="title" min-width="100"/>
    <vxe-column field="lng" title="经度" show-overflow="title" min-width="100"/>
    <vxe-column field="companyID" title="所在企业" minWidth="160" :formatter="({cellValue }) => { return cellValue?companyMap[cellValue]:'' }" />
    <vxe-column field="projectName" title="所在工地" minWidth="160"  />
    <vxe-column field="customerName" title="所属客户" minWidth="160"  />
    <!-- <vxe-column field="accName" title="ACC" minWidth="60">
      <template #default="{ row }">
        <span :class="{green: row.acc}">{{row.accName}}</span>
      </template>
    </vxe-column> -->

    <!--    <vxe-column field="lngLatStr" title="经纬度" minWidth="120">
      <template #default="{ row }">
        <span :class="{red: !row.lngLatStr}">{{row.lngLatStr || '无'}}</span>
      </template>
    </vxe-column>-->
    <!--    <vxe-column field="siteDistance" title="移罐报警" show-overflow="title" min-width="100" :formatter="formatterSiteDistance" sortable/>
    <vxe-column field="deviceID" title="设备号" show-overflow="title" min-width="100" sortable/>
    <vxe-column field="simCard" title="simCard" show-overflow="title" min-width="100" sortable/>-->
  </wz-table>
</template>

<script>
// import { getAddress } from '@/apis/common'
import { getComps } from '@/apis/common'
import Item from '../../../../components/Layout/components/Item'
export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      companyMap: {},
      addressTimer: null
    }
  },
  mounted () {
    // this.getRowAddress()
  },
  created () {
    getComps({}).then(res => {
      if (res.code == 200) {
        const companyMap = res.data.rows.forEach(item => {
          companyMap[item.compId] = Item.companyName
        })
        this.companyMap = companyMap
      }
    }).catch(res => {
      console.log(res)
    })
  },
  beforeDestroy () {
    clearTimeout(this.addressTimer)
  },
  methods: {
    formatterSiteDistance () {},
    formatterLatLng () {},
    // 获取地址
    /* getAddress ({ lat, lng }) {
      return new Promise((resolve) => {
        const params = { lat, lng }
        getAddress(params).then((res) => {
          if (res.code == 200 && res.data.status == 1) {
            resolve(res)
          } else {
            console.error(res.msg)
            // this.$message.error(res.msg)
          }
        })
      })
    }, */

    /**
     * 获取并更新地址
     * 1. 更新列表地址
     * 2. 更新markers地址
     */
    getRowAddress () {
      this.addressTimer = setTimeout(() => {
        const visibleTableData = this.$refs.tableRef.$refs.innerTable.getTableData().tableData
        visibleTableData.forEach((row) => {
          if (!row.address && row.lngLatStr !== '0,0') {
            this.getAddress(row).then((res) => {
              // 更新地址
              this.$emit('update-address', { vehiId: row.vehiId, address: res.data.address })
              // row.address = res.data.address
            })
          }
        })
        this.getRowAddress()
      }, 3000)
    },

    activeRow (row) {
      this.$refs.tableRef.$refs.innerTable.scrollToRow(row)
      this.$refs.tableRef.$refs.innerTable.setCurrentRow(row)
    },

    exportData () {
      this.$refs.tableRef.$refs.innerTable.exportData({
        filename: '砂浆罐列表',
        sheetName: '砂浆罐列表',
        type: 'xlsx'
      })
    }
  }
}
</script>

<style>

</style>
