export function potDataFormat (node) {
  const lngLat = node.lngLat ? (node.lngLat.split(',')) : [0, 0]
  const nodeFormat = {
    busID: node.busID,
    vehiId: node.vehiId,
    cityCode: null,
    compId: node.compId,
    companyID: node.companyID,
    countrCode: null,
    customerID: node.customerID,
    customerName: node.customerName,
    deviId: node.deviId,
    lastFixDatTim: node.lastFixDatTim,
    lngLat: node.lngLat,
    maxPara: node.maxPara,
    maxWeight: node.maxWeig,
    potId: node.potId,
    potNo: node.potNo,
    potSitu: node.potSitu,
    potStatId: node.potStatId,
    potType: node.potType,
    projectID: node.projectID,
    projectName: node.projectName,
    provinCode: node.provinCode,
    showMess: node.showMess,
    warnPert: node.warnPert,
    weigType: node.weigType,
    remark: node.remark,
    weight: node.weight,
    lng: lngLat[0],
    lat: lngLat[1],
    _accoId: node._accoId
  }
  return nodeFormat
}

// 检测是否离线
// function checkedOnlineStatus(node) {
//   if(!!Number(node.onLineStat)) {
//     // (后期最好改为用服务器时间)
//     const serverTime = Date.now()
//     const dateGps = moment(node.lastFixDatTim)
//     const diffSeconds = moment(serverTime).diff(dateGps, 'seconds') // 计算相差的秒数
//     // let offlineSecond = !!node.acc ? 60 : 305
//     let offlineSecond = 300 // 五分钟
//     return !!(diffSeconds < offlineSecond)
//   }
// }
