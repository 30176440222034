<template>
  <!--
    车辆聚合功能按钮，使用方法参考 定位监控组件：src\views\monitor\carMonitor\index.vue

    v-model 控制是否聚合
    clusterLayer: markers layer 实例, 具体参考 mapTalks 或 定位监控组件
  -->
  <el-button
    @click="clusterChange"
    :type="value?'primary':'normal'"
    icon="iconfont icon-ju fs-12"
    circle
    title="车辆聚合"
  ></el-button>
</template>

<script>
export default {
  props: {
    layerIns: {
      type: Object,
      default () {
        return null
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clusterChange () {
      const active = !this.value
      this.$emit('input', active)
      if (this.layerIns) {
        this.layerIns.config({
          maxClusterZoom: active ? 17 : 3
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
