// 格式化车辆数据的字段
import XEUtils from 'xe-utils'

export function busDataFormat (node) {
  let [lng, lat] = node.lngLat ? (node.lngLat.split(',')) : [0, 0]
  lng = XEUtils.round(lng, 6)
  lat = XEUtils.round(lat, 6)
  if (node.weight == '' || node.weight == null) {
    node.weight = 0
  }
  if (node.maxWeig == '' || node.maxWeig == null || node.maxWeig == 0) {
    node.maxWeig = 40
  }
  let persent = Number(node.weight) * 100 / Number(node.maxWeig)
  persent = persent.toFixed(0)
  if (persent > 100) {
    persent = 100
  }

  const isOnline = !!node.onLineStat
  // const speed = node.speed || 0
  const nodeFormat = {
    // vehiId: node.vehiId, // 建材统一平台的车辆id
    busID: node.busID, // 设备id
    potNo: node.potNo,
    potType: node.potType,
    projectID: node.projectID,
    projectName: node.projectName,
    compId: node.compId,
    companyID: node.companyID,
    customerID: node.customerID,
    customerName: node.customerName,
    maxWeight: node.maxWeig,
    maxPara: node.maxPara,
    deviId: node.deviId,
    lastFixDatTim: node.lastFixDatTim ? XEUtils.toDateString(node.lastFixDatTim) : node.lastFixDatTim,
    potId: node.potId,
    bigIcon: getPotIcon({
      isOnline,
      persent,
      type: 'big'
    }),
    persent,
    // acc: !!node.acc,
    // accName: node.acc === 1 ? '开' : '关',
    // angle: node.angle ? 360 - node.angle : undefined,
    // busLicPlate: node.busLicPlate,
    compName: node.compName,
    time: node.lastFixDatTim,
    lng,
    lat,
    lngLat: [lng, lat],
    lngLatStr: `${lng},${lat}`,
    isOnline,
    isOnlineStr: isOnline ? '在线' : '离线',
    ownerCode: node.ownerCode,
    weight: node.weight,
    // speed,
    // speedName: speed + 'km/h',
    // address: '',
    //  mileage: node.mileage || '',
    markerIcon: getPotIcon({
      isOnline,
      persent,
      type: 'another'
    })
    // companyID: node.companyID,
    // deviId: node.deviId,
    // ownType: node.ownType,
    // parentBusID: node.parentBusID,
    // remark: node.remark,
    // offset: node.offset,
    // mapList: node.mapList,
    // tirePresJson: node.tirePresJson,
    // vehiStatId: node.vehiStatId,
  }
  return nodeFormat
}

// 格式化websocket推送来的车辆数据
export function formatWebsocketData (node) {
  const lng = XEUtils.round(node.dimX / 1000000, 6)
  const lat = XEUtils.round(node.dimY / 1000000, 6)
  const isOnline = true
  /* if (node.Weight == '' || node.Weight == null) {
    node.Weight = 0
  }
  let persent = Number(node.Weight) / (Number(node.maxWeigh) * 10)
  persent = persent.toFixed(0)
  if (persent > 100) {
    persent = 100
  } */
  // const speed = node.Speed || 0
  return {
    lng,
    lat,
    // speed,
    busID: node.busID,
    vehiId: node.vehiId,
    // persent,
    lngLat: [lng, lat],
    weight: node.Weight,
    lngLatStr: `${lng},${lat}`,
    angle: node.angle ? 360 - node.angle : undefined,
    lastFixDatTim: node.lastFixTime ? XEUtils.toDateString(node.lastFixTime) : node.lastFixTime,
    // lastFixDatTim: node.lastFixDatTim,
    mileage: node.mileage,
    // speedName: speed + 'km/h',
    isOnline,
    isOnlineStr: '在线'
  }
}
export function getPotIcon (node) {
  let persent = Number(node.persent)
  persent = persent.toFixed(0)
  if (persent > 100) {
    persent = 100
  }
  let persentImg = persent
  if (persentImg < 2) {
    persentImg = 2
  }
  if (persentImg % 2 === 1) {
    persentImg = persentImg - 1
  }
  if (isNaN(persentImg)) {
    persentImg = 2
  }
  if (node.isOnline) {
    if (node.diffWeight) {
      if (node.diffWeight < -39 || node.diffWeight > 160) {
        return node.type == 'big' ? require('@/assets/imgs/mortar/big/red/' + persentImg + '.png') : require('@/assets/imgs/mortar/normal/red/' + persentImg + '.png')
      } else {
        return node.type == 'big' ? require('@/assets/imgs/mortar/big/blue/' + persentImg + '.png') : require('@/assets/imgs/mortar/normal/blue/' + persentImg + '.png')
      }
    } else {
      return node.type == 'big' ? require('@/assets/imgs/mortar/big/blue/' + persentImg + '.png') : require('@/assets/imgs/mortar/normal/blue/' + persentImg + '.png')
    }
  } else {
    return node.type == 'big' ? require('@/assets/imgs/mortar/big/gray/' + persentImg + '.png') : require('@/assets/imgs/mortar/normal/gray/' + persentImg + '.png')
  }
}
