var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("header",null,{"treeData":_vm.tree6}),(_vm.list6.length<=0)?_c('div',{staticClass:"flex justify-center"},[_c('span',{staticClass:"fs-14 mt-20"},[_vm._v("暂无数据")])]):_c('vxe-list',{ref:"myTree",staticClass:"my-tree",attrs:{"height":_vm.height-_vm.headerHeight+'px',"loading":_vm.loading6,"data":_vm.list6,"scroll-y":{gt:0,oSize:50},"auto-resize":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('div',{key:item[_vm.idProp],staticClass:"my-tree-item flex flex-nowrap align-center",class:[
          ("level-" + (item._LEVEL)),
          {'has-child':item._HAS_CHILDREN,'is-expand':item._EXPAND}
        ],style:({paddingLeft: ((item._LEVEL*23) + "px")})},[_c('i',{staticClass:"iconfont fs-12",class:item._EXPAND?'icon-shanchu':'icon-tianjia',style:({visibility:item._HAS_CHILDREN?'visible':'hidden'}),on:{"click":function($event){return _vm.toggleTreeNode(item)}}}),(_vm.checkType=='checkbox')?_c('el-checkbox',{staticClass:"ml-10",attrs:{"indeterminate":item._IS_HALF,"value":item._IS_CHECK},on:{"input":function($event){_vm.toogleNodeCheck(item[_vm.idProp], $event),_vm.$emit('checked-node')}}}):_vm._e(),(_vm.checkType=='radio'&&!item._HAS_CHILDREN)?_c('el-radio',{staticClass:"ml-10 mr-0",attrs:{"value":_vm.radioId,"label":item[_vm.idProp]},on:{"input":_vm.setRadio}}):_vm._e(),_c('div',{staticClass:"tree-name flex align-center ml-5",class:{active:_vm.activeId==item[_vm.idProp]},on:{"click":function($event){return _vm.onTreeClick(item)},"dblclick":function($event){return _vm.onTreeDblclick(item)},"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onTreeContextmenu(item)}}},[(item._HAS_CHILDREN)?_vm._t("prefix",[_c('i',{class:[
                'iconfont mb-2',
                item._EXPAND?'icon-wenjianjiazhankai':'icon-wenjianjiashouqi'
              ],style:({color:_vm.$store.state.settings.theme})})],{"item":item}):_vm._t("prefixLeaf",[_c('i',{class:[
                'iconfont mb-2',
                item._EXPAND?'icon-wenjianjiazhankai':'icon-wenjianjiashouqi'
              ],style:({color:_vm.$store.state.settings.theme})})],{"item":item}),_c('div',{staticClass:"text-1line flex-1 ml-5 fs-12"},[_vm._t("title",[_vm._v(" "+_vm._s(item.name)+" ")],{"item":item})],2),_c('div',{staticClass:"flex-shrink flex align-center ml-5"},[_vm._t("suffix",null,{"item":item})],2)],2)],1)})}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }