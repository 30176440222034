<template>
  <div>
    <slot name="header" v-bind:treeData="tree6"></slot>
    <div class="flex justify-center" v-if="list6.length<=0">
      <span class="fs-14 mt-20">暂无数据</span>
    </div>
    <vxe-list
      v-else
      ref="myTree"
      class="my-tree"
      :height="height-headerHeight+'px'"
      :loading="loading6"
      :data="list6"
      :scroll-y="{gt:0,oSize:50}"
      :auto-resize="true">
      <template #default="{ items }">
        <div
          class="my-tree-item flex flex-nowrap align-center"
          v-for="item in items"
          :key="item[idProp]"
          :class="[
            `level-${item._LEVEL}`,
            {'has-child':item._HAS_CHILDREN,'is-expand':item._EXPAND}
          ]"
          :style="{paddingLeft: `${item._LEVEL*23}px`}">

          <!-- 展开按钮 -->
          <i
            class="iconfont fs-12"
            :style="{visibility:item._HAS_CHILDREN?'visible':'hidden'}"
            :class="item._EXPAND?'icon-shanchu':'icon-tianjia'"
            @click="toggleTreeNode(item)"
          />

          <!-- 复选框 -->
          <el-checkbox
            v-if="checkType=='checkbox'"
            class="ml-10"
            :indeterminate="item._IS_HALF"
            :value="item._IS_CHECK"
            @input="toogleNodeCheck(item[idProp], $event),$emit('checked-node')"
          ></el-checkbox>

          <!-- 单选 -->
          <el-radio
            class="ml-10 mr-0"
            v-if="checkType=='radio'&&!item._HAS_CHILDREN"
            :value="radioId"
            :label="item[idProp]"
            @input="setRadio"
          ></el-radio>

          <div
            class="tree-name flex align-center ml-5"
            :class="{active:activeId==item[idProp]}"
            @click="onTreeClick(item)"
            @dblclick="onTreeDblclick(item)"
            @contextmenu.prevent.stop="onTreeContextmenu(item)"
          >

            <!-- 前缀 -->
            <!-- <div class="pre-fix flex flex-shrink ml-5"> -->
            <!-- 目录图标 -->
            <slot v-if="item._HAS_CHILDREN" name="prefix" v-bind:item="item">
              <i
                :style="{color:$store.state.settings.theme}"
                :class="[
                  'iconfont mb-2',
                  item._EXPAND?'icon-wenjianjiazhankai':'icon-wenjianjiashouqi'
                ]"
              />
            </slot>

            <!-- 叶子图标 -->
            <slot v-else name="prefixLeaf" v-bind:item="item">
              <!-- <i
                  class="iconfont icon-leaf-01"
                  :style="{color:$store.state.settings.theme}"
                /> -->
              <i
                :style="{color:$store.state.settings.theme}"
                :class="[
                  'iconfont mb-2',
                  item._EXPAND?'icon-wenjianjiazhankai':'icon-wenjianjiashouqi'
                ]"
              />
            </slot>
            <!-- </div> -->

            <!-- 标题 -->
            <div class="text-1line flex-1 ml-5 fs-12">
              <slot name="title" v-bind:item="item">
                {{ item.name }}
              </slot>
            </div>

            <!-- 后缀 -->
            <div class="flex-shrink flex align-center ml-5">
              <slot name="suffix" v-bind:item="item"></slot>
            </div>
          </div>
        </div>
      </template>
    </vxe-list>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'

const vueIns = {
  props: {
    checkType: { type: String, default: 'checkbox' },
    height: { type: Number, default: 400 },
    headerHeight: { type: Number, default: 36 },
    props: {
      type: Object
    }
  },
  computed: {
    getProps () {
      return Object.assign({
        id: 'id',
        name: 'name'
      }, this.props)
    },
    idProp () {
      return this.getProps.id
    }
  },
  data () {
    return {
      radioId: '',
      loading6: false,
      list6: [], // 虚拟列表数据
      tree6: [], // 树数据，在源数据上做了处理
      treeMap: new Map(),
      activeId: -1
    }
  },
  methods: {
    onTreeDblclick (row) {
      this.toggleTreeNode(row)
      this.$emit('node-bdlclick', JSON.parse(JSON.stringify(row)))
    },

    onTreeContextmenu (row) {
      this.$emit('node-contextmenu', JSON.parse(JSON.stringify(row)))
    },

    onTreeClick (row) {
      this.activeId = row[this.idProp]
      if (this.checkType == 'radio' && !row._HAS_CHILDREN) {
        this.setRadio(row[this.idProp])
      }
      this.$emit('node-click', JSON.parse(JSON.stringify(row)))
    },

    setRadio (value) {
      this.radioId = value
      this.$emit('radio-change')
    },

    getRadioNode () {
      return this.treeMap.get(this.radioId)
    },

    getRadioId () {
      return this.radioId
    },

    loadTree (treeData) {
      treeData = JSON.parse(JSON.stringify(treeData))
      // 将树结构拍平，构建列表树结构，并存一份Map数据方便查查找
      const treeMap = new Map()
      XEUtils.eachTree(treeData, (item, index, items, paths, parent, nodes) => {
        // 层级
        item._LEVEL = nodes.length - 1
        // 是否展开
        item._EXPAND = false
        // 是否可视
        item._VISIBLE = !item._LEVEL
        // 是否有子节点
        item._HAS_CHILDREN = item.children && item.children.length > 0
        // 是否叶子节点
        item._IS_LEAF = !item._HAS_CHILDREN
        // 是否选中
        item._IS_CHECK = false
        // 是否半选
        item._IS_HALF = false
        // 存一份Map方便查询更新节点数据
        treeMap.set(item[this.idProp], item)
      })
      this.tree6 = treeData
      this.treeMap = treeMap
      this.refreshTree()
    },

    reloadTree (treeData) {
      this.tree6 = treeData
      const treeMap = new Map()
      XEUtils.eachTree(treeData, (item, index, items, paths, parent, nodes) => {
        // 存一份Map方便查询更新节点数据
        treeMap.set(item[this.idProp], item)
      })
      this.treeMap = treeMap
      this.refreshTree()
    },

    // 展开节点
    toggleTreeNode (row) {
      if (row._HAS_CHILDREN) {
        this.setTreeExpand(row[this.idProp], !row._EXPAND)
      }
    },

    // 展开某个节点
    setTreeExpand (id, isExpand) {
      // 在树种找到该节点
      const matchObj = XEUtils.findTree(this.tree6, item => item[this.idProp] === id)
      if (matchObj) {
        matchObj.item._EXPAND = isExpand
        XEUtils.eachTree(matchObj.item.children, (item, index, items, path, parent) => {
          item._VISIBLE = parent ? parent._EXPAND && parent._VISIBLE : isExpand
        })
      }
      this.refreshTree()
    },

    // 展开,收起全部
    allTreeExpand (isExpand) {
      if (isExpand) {
        XEUtils.eachTree(this.tree6, item => {
          item._EXPAND = item._HAS_CHILDREN
          item._VISIBLE = true
        })
      } else {
        XEUtils.eachTree(this.tree6, item => {
          item._EXPAND = false
          item._VISIBLE = !item._LEVEL
        })
      }
      this.refreshTree()
    },

    // 把树转为list，并移除children，过滤掉不显示的item
    refreshTree () {
      const treeData = this.tree6
      const treeList = XEUtils.toTreeArray(treeData, undefined, true)
      this.list6 = treeList.filter(item => item._VISIBLE)
    },

    // 批量选中
    toogleNodeChecks (ids, isCheck) {
      ids.forEach((id) => {
        this.toogleNodeCheck(id, isCheck)
      })
    },

    // toogle选中状态
    toogleNodeCheck (id, isCheck) {
      // 在tree中找到当前行的数据
      const matchObj = this.treeMap.get(id)
      if (matchObj) {
        // 选中之后要重置半选状态
        matchObj._IS_CHECK = isCheck
        matchObj._IS_HALF = false

        // 设置孩子的选中状态
        XEUtils.eachTree(matchObj.children, (item, index, items, path, parent) => {
          item._IS_CHECK = isCheck
          item._IS_HALF = false
        })

        // 向上修改上级的选中状态：可能是半选，全选，不选
        this.updateParentCheckStatus(matchObj.parent)
      }
    },

    // 更新父节点的选中状态
    updateParentCheckStatus (row) {
      const matchObj = XEUtils.findTree(this.tree6, item => item === row)

      if (matchObj) {
        let checkedNum = 0 // 记录全选数量
        let halfCheckedNum = 0 // 记录半选数量
        const children = row.children
        const childNum = children.length
        children.forEach((item) => {
          if (item._IS_CHECK) checkedNum++
          if (item._IS_HALF) halfCheckedNum++
        })

        // 全选，半选数量都为0 =》 不选
        if (checkedNum === 0 && halfCheckedNum === 0) {
          this.setCheckStatus(row, 3)

        // 全选数量等于孩子数量 =》 全选
        } else if (checkedNum >= childNum) {
          this.setCheckStatus(row, 1)

        // 剩余情况都是半选
        } else {
          this.setCheckStatus(row, 2)
        }

        this.updateParentCheckStatus(matchObj.parent)
      }
    },

    /**
     * row 点击行的数据
     * status: 1 全选， 2 半选，3 不选
     */
    setCheckStatus (row, status) {
      if (status == 1) {
        row._IS_CHECK = true
        row._IS_HALF = false
      } else if (status == 2) {
        row._IS_CHECK = false
        row._IS_HALF = true
      } else if (status == 3) {
        row._IS_CHECK = false
        row._IS_HALF = false
      }
    },

    // 更新某个节点的数据
    updateNodeData (id, newData) {
      const node = this.treeMap.get(id)
      if (node) {
        XEUtils.merge(node, newData)
      }
    },

    // 获取被选中的叶子节点的id
    getLeafCheckedIds (propName) {
      propName = propName || this.idProp
      const ids = []
      XEUtils.eachTree(this.tree6, (item, index, items, path, parent) => {
        if (!item._HAS_CHILDREN && item._IS_CHECK) ids.push(item[propName])
      })
      return ids
    },

    // 获取被选中的叶子节点的id
    getLeafCheckedNodes (propName) {
      propName = propName || this.idProp
      const nodes = []
      XEUtils.eachTree(this.tree6, (item, index, items, path, parent) => {
        item = JSON.parse(JSON.stringify(item))
        if (!item._HAS_CHILDREN && item._IS_CHECK) nodes.push(item)
      })
      return nodes
    },

    // 找到并激活某一节点
    activeNode (id) {
      const matchObj = XEUtils.findTree(this.tree6, item => item[this.idProp] === id)

      // 展开所有的父级
      this.expandAllParants(matchObj.parent)

      const index = this.list6.findIndex(item => item[this.idProp] == id)

      if (index >= 0) {
        this.$nextTick(() => {
          this.$refs.myTree.recalculate()
        })

        setTimeout(() => {
          const top = index * 28
          this.$refs.myTree.scrollTo(0, top)
        }, 20)
        this.activeId = id
      }
    },

    // 展开父级
    expandAllParants (pNode) {
      const matchObj = XEUtils.findTree(this.tree6, item => item[this.idProp] === pNode[this.idProp])

      if (!matchObj.item._EXPAND) {
        this.setTreeExpand(matchObj.item[this.idProp], true)
      }

      if (matchObj.parent) {
        this.expandAllParants(matchObj.parent)
      }
    },

    // 获取树数据
    getTreeData () {
      return JSON.parse(JSON.stringify(this.tree6))
    },

    getTreeMap () {
      return JSON.parse(JSON.stringify(this.treeMap))
    }
  }
}
export default vueIns
</script>

<style lang="scss" scoped>
.my-tree .my-tree-item {
  height: 28px;
  line-height: 28px;
}
.my-tree .my-tree-item.has-child .tree-icon {
  visibility: visible;
  transition: all 0.3s;
}
.my-tree .my-tree-item.is-expand .tree-icon {
  transform: rotate(90deg);
}
.my-tree .tree-icon {
  cursor: pointer;
  width: 20px;
  line-height: 20px;
  text-align: center;
  visibility: hidden;
  user-select: none;
}
.tree-name {
  user-select: none;
  cursor: pointer;
  padding: 0 5px;
  &.active,
  &:hover {
    background-color: #f5f7fa;
  }
}

.my-tree {
  overflow-x: hidden;
}
/*滚动条整体部分*/
.my-tree ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.my-tree ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}
/*滚动条里面的小方块，能向上向下移动*/
.my-tree ::-webkit-scrollbar-thumb {
  background-color: rgba(144,147,153,.5);
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.my-tree ::-webkit-scrollbar-thumb:hover {
  // background-color: #A8A8A8;
  background-color: rgba(144,147,153,.4);

}
.my-tree ::-webkit-scrollbar-thumb:active {
  background-color: rgba(144,147,153,.5);
}
/*边角，即两个滚动条的交汇处*/
.my-tree ::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
::v-deep .el-radio__label {
  display: none;
}
</style>
