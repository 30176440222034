<template>
  <resize-wrap class="h-100-p">
    <template v-slot="{height}">
      <virtual-tree
        v-loading="loading"
        ref="potTreeRef"
        @node-bdlclick="$emit('dblclick-node',$event)"
        @checked-node="checkedNode"
        @radio-change="radioChange"
        :height="height"
        :check-type="checkType"
      >
        <!-- 搜索栏  -->
        <template v-slot:header="">
          <el-autocomplete ref="myAutocomplete" :placeholder="searchModel==='1'?'请输入罐号':'请输入工地'" v-model="searchKey" style="width: 100%" class="input-with-select" size="mini" :clearable="true" :trigger-on-focus="true"
                           :fetch-suggestions="querySearch" @select="handleSelect"
                           @clear="clearSearch">
            <el-select v-model="searchModel" slot="prepend" placeholder="请选择" style="width: 80px" @change="searchModelChange" size="mini">
              <el-option label="罐号" value="1"/>
              <!--              <el-option label="工地" value="2"/>-->
            </el-select>
            <el-dropdown slot="append" size="small">
              <i class="el-icon-setting" style="cursor: pointer;font-size: 14px;"></i>
              <el-dropdown-menu slot="dropdown" size="small">
                <el-dropdown-item @click.native="refreshTree" icon="el-icon-refresh-right">刷新</el-dropdown-item>
                <el-dropdown-item @click.native="treeTypeChange('dev')">按罐子</el-dropdown-item>
                <el-dropdown-item @click.native="treeTypeChange('project')">按工地</el-dropdown-item>
                <!--                <el-dropdown-item @click.native="showTypeChange(treeData,3)">客户-罐子</el-dropdown-item>&ndash;&gt;-->
              </el-dropdown-menu>
            </el-dropdown>
          </el-autocomplete>
        </template>

        <!-- 标题 -->
        <template v-slot:title="{item}">
          <!-- title -->
          {{getLabel(item, labelType)}}
          <!-- 在线数 -->
          <template v-if="item.totalNum>=0">({{item.onlineNum||0}}/{{item.totalNum}})</template>
        </template>

        <!-- 图标 -->
        <template v-slot:prefixLeaf="{item}">
          <img v-if="item.type=='DEV'" :style="!(item.detail&&item.detail.isOnline)?'filter:grayscale(1)':''" src="@/assets/imgs/monitor/icon/tree_mortar_online.png">
        </template>

        <!-- 功能按钮 -->
        <template v-slot:suffix="{item}">
          <template v-if="item.type=='DEV'"></template>
        </template>
      </virtual-tree>
    </template>
  </resize-wrap>
</template>

<script>
// import CircularJson from 'circular-json'
import XEUtils from 'xe-utils'
import VirtualTree from '@/components/VirtualTree'
// import mortarTreeSearchBox from './components/mortarTreeSearchBox'
import { getPotTree0, getPotList } from '@/apis/monitor/mortarMonitor'
import { addMessageLisner } from '@/utils/websocket'
import { potDataFormat } from './formatPotData'
import moment from 'moment'
export default {
  props: {
    checkType: { type: String, default: 'checkbox' }
  },
  data () {
    return {
      labelType: 1,
      treeType: '',
      loading: false,
      serverDiff: 0, // serverHs - currHs == serverDiff
      calcTimeout: 5000, // 计算在线数量频率，数据越大频率月底
      calcTimer: null,
      searchModel: '1',
      showType: '',
      searchKey: '',
      params: {
        // 1: '{"PROVINCE":1,"CITY":2,"DISTRICT":3,"DEV":4}', // 区域
        // 2: '{"COMPANY":1,"POT":2}' // 企业/机构
        2: '{"key":"DEV","parentKey":"PROJECT"}'
        // 2:{"json": "{\"DISTRICT\":1,\"COMPANY\":2,\"POT\":3}","areaCodeList":[330000]}
      //  4: '{"PROVINCE":1,"CITY":2,"DISTRICT":3,"COMPANY":4,"DEV":5}' // 区域 + 企业/机构
      }
    }
  },
  async mounted () {
    // 默认是按企业展示
    /* this.showTypeChange([], 2) */

    // 初始化树的数据
    await this.initPotTree()

    // 接口请求回来3s之后 开始推送
    setTimeout(() => {
      addMessageLisner(25, this.onWsMessage)
    }, 3000)
  },
  beforeDestroy () {
    clearTimeout(this.calcTimer)
    this.calcTimer = null
  },
  methods: {
    treeTypeChange (type) {
      this.showType = type
      if (type == 'dev') {
        clearTimeout(this.calcTimer)
        this.calcTimer = null
        this.initPotTree(false, false)
      } else if (type == 'project') {
        clearTimeout(this.calcTimer)
        this.calcTimer = null
        this.initPotTree2(false, false)
      }
    },
    searchModelChange (val) {

    },
    querySearch (queryString, cb) {
      queryString = queryString.toLowerCase()
      const suggestList = []
      const { length } = queryString.replace(/[^a-zA-Z0-9]/g, '')
      const data = this.$refs.potTreeRef.getTreeData()
      if (length >= 2) {
        XEUtils.eachTree(data, ({ id, type, detail }) => {
          if (type == 'DEV') {
            if (this.searchModel === '1') {
              const potNo = detail.potNo || ''
              const searchStr = (potNo).toLowerCase()
              if (searchStr.includes(queryString)) {
                suggestList.push({
                  value: `${potNo}`,
                  id,
                  detail,
                  type,
                  busID: detail.busID
                })
              }
            } else {
              const projectName = detail.projectName || ''
              const searchStr = (projectName).toLowerCase()
              if (searchStr.includes(queryString)) {
                suggestList.push({
                  value: `${projectName}`,
                  id,
                  detail,
                  type
                })
              }
            }
          }
        })
      }
      cb(suggestList)
    },
    handleSelect (item) {
      // this.zTree.selectNode(item.node)
      // 筛选并选中某个节点
      if (this.checkType == 'checkbox') {
        this.$refs.potTreeRef.activeNode(item.id)
      } else {
        this.$refs.potTreeRef.activeNode(item.id)
        this.$refs.potTreeRef.setRadio(item.id)
      }
      this.$emit('actived-node', item)
    },
    clearSearch () {
      this.$refs.myAutocomplete.handleFocus()
    },
    showTypeChange (treeData, val) {
      this.treeTypeChange(val)
    },
    formatWsData (node) {
      const lng = XEUtils.round(node.DimX / 1000000, 6)
      const lat = XEUtils.round(node.DimY / 1000000, 6)
      return {
        lng,
        lat,
        lngLat: [lng, lat],
        busID: node.busID,
        potId: node.potId,
        lastFixDatTim: XEUtils.toDateString(node.LastFixTime),
        mileage: node.mileage,
        weight: node.Weight,
        // speed: node.Speed,
        // speedName: node.Speed + 'km/h',
        isOnline: true,
        isOnlineStr: '在线'
      }
    },

    onWsMessage (data) {
      data.forEach(item => {
        const newNode = this.formatWsData(item)
        this.onDriverMessage(newNode)
      })
    },

    updateBusTree () {
      setTimeout(() => {
        getPotList().then((res) => {
          if (res.code == 200) {
            res.data.forEach((node) => {
              // 格式化数据，保持和websocket一致
              const newNode = potDataFormat(node)
              this.onDriverMessage(newNode)
            })
            this.updateBusTree()
          } else {
            this.$message.error(res.msg)
          }
        })
      }, 10000)
    },

    async initListAndMarkers () {
      const res = await getPotList()
      if (res.code == 200) {
        this.tableDataMap = new Map()
        const tableData = this.tableData = res.data.map((node) => {
          // 格式化数据，保持和websocket一致
          node = potDataFormat(node)
          this.tableDataMap.set(node.vehiId, node)
          return node
        })
        this.initMarkers(tableData)
        // 更新经纬度对应的地址
        setTimeout(() => {
          this.initRowAddress()
        }, 5000)
      } else {
        this.$message.error(res.msg)
      }
    },

    checkedNode () {
      this.$emit('checked-node', this.$refs.potTreeRef.getLeafCheckedIds('busID'))
    },

    radioChange () {
      this.$emit('radio-change', this.$refs.potTreeRef.getRadioNode())
    },

    setRadio (id) {
      this.$refs.potTreeRef.setRadio = id
    },
    async getPotData (cancel, cache) {
      const data = []
      const busTask = getPotTree0({ key: 'DEV', parentKey: 'PROJECT' }, cancel, cache).then((res) => {
        data.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: (res.data.rtmTreeList.treeList || [])
        })
      })

      await Promise.all([busTask])
      return data
    },

    async getPotData2 (cancel, cache) {
      const data1 = []
      const data2 = []
      const projTask = getPotTree0({ key: 'PROJECT', parentKey: '' }, cancel, cache).then((res) => {
        data1.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: (res.data.rtmTreeList.treeList || [])
        })
      })
      const busTask = getPotTree0({ key: 'DEV', parentKey: 'PROJECT' }, cancel, cache).then((res) => {
        data2.push({
          sort: res.data.rtmTreeList.sort,
          tree0List: (res.data.rtmTreeList.treeList || [])
        })
      })

      await Promise.all([projTask, busTask])
      return [...data1, ...data2]
    },

    // cancel 是否切换页面取消接口， cache 是否取缓存的数据
    async initPotTree (cancel, cache) {
      this.loading = true
      const data = await this.getPotData(cancel, cache)
      let list = [{ id: '0', name: '全部', totalNum: 0, onlineNum: 0, type: 'ROOT', children: [] }]
      let carCount = 0
      data.forEach(({ tree0List }, index) => { // 执行n条数据次
        tree0List = tree0List.map(item => {
          // 车辆节点需要 格式化, 保持和websocket一致
          if (item.type == 'DEV') {
            item.detail = potDataFormat(item.detail)
            carCount++
          } else {
            item.totalNum = 0
            item.onlineNum = 0
          }
          item.pId = item.pid || '0'
          item.children = []
          return item
        })

        list.push(...tree0List)
        this.calcTimeout = carCount > 2000 ? carCount : 1000
      })

      // 需要过滤调子节点为0的类型：省，市，区，企业，机构
      const filterType = ['PROJECT', 'COMPANY', 'ORG']
      list = list.filter(item => {
        if (filterType.includes(item.type)) {
          const isHava = list.some(subItem => (item.id == subItem.pId))
          return isHava
          // 不需要处理的直接返回
        } else {
          return true
        }
      })

      // 把list转为tree
      const treeData = this.listToTreeList(list)
      // 初始化树组件
      this.$refs.potTreeRef.loadTree(treeData)
      // 选中全部车辆
      this.$refs.potTreeRef.toogleNodeChecks(['0'], true)
      // 展开全部车辆
      this.$refs.potTreeRef.setTreeExpand('0', true)
      this.loading = false

      // 定时计算在线数量
      this.calcTimer = this.computeBusOnlineNum()
    },
    async initPotTree2 (cancel, cache) {
      this.loading = true
      const data = await this.getPotData2(cancel, cache)
      console.log(111, data)
      let list = [{ id: '0', name: '全部', totalNum: 0, onlineNum: 0, type: 'ROOT', children: [] }]
      let carCount = 0
      data.forEach(({ tree0List }, index) => { // 执行n条数据次
        tree0List = tree0List.map(item => {
          // 车辆节点需要 格式化, 保持和websocket一致
          if (item.type == 'DEV') {
            item.detail = potDataFormat(item.detail)
            carCount++
          } else {
            item.totalNum = 0
            item.onlineNum = 0
          }
          if (item.type == 'PROJECT' && item.id == '0') {
            item.id = 'p0'
          }
          item.pId = item.pid || '0'
          item.children = []
          return item
        })

        list.push(...tree0List)
        this.calcTimeout = carCount > 2000 ? carCount : 1000
      })

      // 需要过滤调子节点为0的类型：省，市，区，企业，机构
      const filterType = ['PROJECT', 'COMPANY', 'ORG']
      list = list.filter(item => {
        if (filterType.includes(item.type)) {
          const isHava = list.some(subItem => (item.id == subItem.pId))
          return isHava
          // 不需要处理的直接返回
        } else {
          return true
        }
      })

      // 把list转为tree
      const treeData = this.listToTreeList(list)
      // treeData = CircularJson.parse(treeData)
      // 初始化树组件
      this.$refs.potTreeRef.loadTree(treeData)
      // 选中全部车辆
      this.$refs.potTreeRef.toogleNodeChecks(['0'], true)
      // 展开全部车辆
      this.$refs.potTreeRef.setTreeExpand('0', true)
      this.loading = false

      // 定时计算在线数量
      this.calcTimer = this.computeBusOnlineNum()
    },

    refreshTree () {
      this.$emit('refresh')
      clearTimeout(this.calcTimer)
      this.calcTimer = null
      const showType = this.showType || 'dev'
      this.treeTypeChange(showType)
    },

    activedNode (item) {
      // 筛选并选中某个节点
      if (this.checkType == 'checkbox') {
        this.$refs.potTreeRef.activeNode(item.treeId)
      } else {
        this.$emit('actived-node', item)
        this.$refs.potTreeRef.activeNode(item.treeId)
        this.$refs.potTreeRef.setRadio(item.treeId)
      }
      this.$emit('actived-node', item)
    },

    // 获取节点的名称
    getLabel ({ name, type, detail }, labelType) {
      if (type == 'DEV') {
        const potNo = `${[1, 3].includes(labelType) ? detail.potNo : ''}`
        const weight = detail.weight || 0
        return potNo + '(' + weight + 't)'
      } else {
        return name
      }
    },

    // websocket数据更新车数据
    onDriverMessage (newNode) {
      // console.log(msg, 'msg')
      const treeId = 'DEV' + newNode.busID
      const { lastFixDatTim, isOnline, isOnlineStr, weight } = newNode
      this.$refs.potTreeRef.updateNodeData(treeId, { detail: { lastFixDatTim, isOnline, isOnlineStr, weight } })
    },

    // 计算车的在线数量，并检测是否离线
    computeBusOnlineNum () {
      const treeData = this.$refs.potTreeRef.getTreeData()
      if (treeData) {
        treeData.forEach((item) => {
          // 递归计算并更新节点的在线数量
          this.calCurrentNodeNum(item)
        })
      }

      return setTimeout(() => {
        this.calcTimer = this.computeBusOnlineNum()
      }, this.calcTimeout)
    },
    handleCommand (command) {
      if (command == 'refresh') {
        this.$emit('refresh')
      } else {
        clearTimeout(this.calcTimer)
        this.calcTimer = null
        this.initPotTree2(false, false)
      }
    },

    // 计算节点下车辆在线数，计算节点下车辆总数
    calCurrentNodeNum (node) {
      const pEnums = ['ROOT', 'PROJECT', 'CITY', 'DISTRICT', 'COMPANY', 'ORG']
      const sumObj = { totalNum: 0, onlineNum: 0 }

      if (pEnums.includes(node.type) && node.children && node.children.length > 0) {
        node.children.forEach((item) => {
          if (item.type == 'DEV') {
            const currOnline = item.detail.isOnline
            // 检测是否离线
            // const isOnline = this.checkedOnlineStatus(item)

            // 车辆离线
            // if(currOnline && !isOnline) {
            //   this.$refs.treeRef.updateNodeData(item.treeId, { detail: { isOnline, isOnlineStr: '离线'} })
            //   currOnline = isOnline
            //   this.$emit('offline')
            // }

            // 如果是在线就增加在线数量
            sumObj.onlineNum += currOnline ? 1 : 0
            sumObj.totalNum += 1
          } else {
            const { onlineNum, totalNum } = this.calCurrentNodeNum(item)
            sumObj.onlineNum += onlineNum
            sumObj.totalNum += totalNum
          }
        })

        // 更新节点数据
        this.$refs.potTreeRef.updateNodeData(node.id, sumObj)
      }
      return sumObj
    },

    // 检测是否离线
    checkedOnlineStatus (node) {
      if (node.detail.isOnline) {
        // 获取服务器时间
        const serverTime = this.serverDiff + Date.now()
        const dateGps = moment(node.detail.time)
        const diffSeconds = moment(serverTime).diff(dateGps, 'seconds') // 计算相差的秒数
        const offlineSecond = node.detail.acc ? 60 : 305
        return !!(diffSeconds > offlineSecond)
      }
    },
    listToTreeList (list) {
      if (!list || !list.length) {
        return []
      }
      const treeListMap = {}
      for (const item of list) {
        treeListMap[item.id] = item
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].pId && treeListMap[list[i].pId]) {
          if (!treeListMap[list[i].pId].children) {
            treeListMap[list[i].pId].children = []
          }
          treeListMap[list[i].pId].children.push(list[i])
          list.splice(i, 1)
          i--
        }
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == 'DEV') {
          treeListMap['0'].children.push(list[i])
          list.splice(i, 1)
          i--
        }
      }
      console.log(77, list)
      return list
    }

  },
  components: {
    VirtualTree
  }
}
</script>
