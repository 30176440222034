<template>
  <page-container type="fullframe">
    <div class="relative bg-white h-100-p">
      <!-- 左树 -->
      <split-pane split-to="columns" :size="330" :allow-resize="true">
        <div slot="firstPane" class="flex-column p-15 h-100-p">
          <mortar-tree
            @refresh="refreshBusList"
            @dblclick-node="dblClickNode"
            @actived-node="activedNode"
            @checked-node="checkedNode"
            ref="treeRef"
          />
        </div>
        <div slot="secondPane" class="h-100-p">
          <el-tabs  class="h-100-p" type="border-card" @tab-click="tabClick" v-model="tabModel" ref="tabRef" :v-if="true">
            <el-tab-pane label="按地图" class="tab-content" name="tabMap" >
              <split-pane units="percents" split-to="rows" :size="60"  >
                <div slot="firstPane" class="h-100-p">
                  <BaseMap
                    ref="mortarMapRef"
                    @change="handlerMapLayerChange"
                  >
                    <MarkerTogetherIcon
                      slot="prefix-action"
                      v-model="activeTogether"
                      :layer-ins="clusterLayer"
                    />
                  </BaseMap>
                </div>
                <div slot="secondPane" class="table-box flex flex-column h-100-p">
                  <el-tabs type="border-card" class="h-100-p w-100-p" >
                    <el-tab-pane label="砂浆罐列表" class="tab-content">
                      <CarTable
                        v-loading="loading"
                        ref="carTable"
                        :data="tableData"
                        @update-address="updateRowAddress"
                        @cell-dblclick="dblclickRow"
                      ></CarTable>
                    </el-tab-pane>
                    <!--                  <el-tab-pane label="离线异常" class="tab-content">
                      &lt;!&ndash;                    <MortarUnlockWeight ref="mortarUnlockWeight" style="margin-top: -1px;"/>&ndash;&gt;
                    </el-tab-pane>-->
                  </el-tabs>
                </div>
              </split-pane>
            </el-tab-pane>
            <el-tab-pane label="按工地" class="tab-content" name="tabProject">
              <MortarByProject ref="mortarByProject" :show-mortar-info="showMortarInfo"/>
            </el-tab-pane>
            <el-tab-pane label="按余量" class="tab-content" name="tabPersent">
              <MortarByPersent ref="mortarByPersent" :show-mortar-info="showMortarInfo"/>
            </el-tab-pane>
          </el-tabs>
        </div>
      </split-pane>
      <el-dialog
        title="砂浆罐信息"
        :visible.sync="mortarInfoDialog"
        :append-to-body="true"
        v-dialogDrag
        width="450px">

        <div style="display:flex;flex-direction: column;align-items: center;justify-content: center;margin-top:10px">

          <div style="display: flex;flex-direction: row;width:95%;padding-right: 10px">

            <div style="width:120px;display: flex;justify-content:center;flex-direction: column;align-items: center;border-right: #D3D3D3 solid 1px">
              <div :style="{backgroundImage:'url('+currentMortarInfo.bigIcon+')'}" style="width: 65px;height: 95px">
              </div>
              <div style="width: 65px;text-align: center">
                {{currentMortarInfo.persent}}%
              </div>
            </div>
            <div style="flex: 2;display: flex;flex-direction: column;margin-left: 10px">
              <div>
                罐编号:{{currentMortarInfo.potNo}}
              </div>
              <div>
                砂浆类型:{{currentMortarInfo.potType}}
              </div>
              <div>
                余量:{{currentMortarInfo.weight}}(吨)
              </div>
              <div>
                时间:{{currentMortarInfo.lastFixDatTim}}
              </div>
              <div>
                状态:{{currentMortarInfo.isOnlineStr}}
              </div>
              <div>
                所在工地:{{currentMortarInfo.projectName}}
              </div>
              <div>
                所属客户:{{currentMortarInfo.customerName}}
              </div>
            </div>
          </div>
        </div>
        <!--      <span slot="footer" class="dialog-footer">-->
        <!--       <el-button @click="mortarInfoDialog = false" size="mini">关闭</el-button>-->
        <!--      </span>-->
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
import * as maptalks from 'maptalks'
import SplitPane from '@/components/SplitPane'
import CarTable from '@/views/monitor/mortarMonitor/components/CarTable'
import mortarTree from './components/mortarTree'
import MortarByProject from './components/MortarByProject'
import MortarByPersent from './components//MortarByPersent'
// import { ClusterLayer } from 'maptalks.markercluster'
import { formatLngLat } from '@/components/BaseMap/util'
import BaseMap from '@/components/BaseMap'
import MarkerTogetherIcon from '@/components/BaseMap/MarkerTogetherIcon'
import { getPotList } from '@/apis/monitor/mortarMonitor'
import { busDataFormat, formatWebsocketData, getPotIcon } from './components/util'
import { addMessageLisner } from '@/utils/websocket'
import { ClusterLayer } from 'maptalks.markercluster'
export default {
  name: 'index',
  data () {
    return {
      closeIcon: '',
      mortarInfoDialog: false,
      currentMortarInfo: {},
      tabModel: 'tabMap',
      // 使用Map，用空间换时间
      tableData: [],
      tableDataMap: new Map(),
      markers: [],
      markersMap: new Map(),
      loading: false,
      checkedIds: null,
      clusterLayer: null, // 散点图层
      activeMarkerId: '',
      activeTogether: false,
      extent: null
    }
  },
  components: {
    SplitPane,
    mortarTree,
    CarTable,
    BaseMap,
    MarkerTogetherIcon,
    MortarByProject,
    MortarByPersent
  },
  created () {
    window.closeInfoWin2 = this.closeInfoWin2
    window.closeMarkerCard = (busID) => {
      const markerIns = this.markersMap.get(busID)
      if (markerIns) {
        markerIns.closeInfoWindow()
        this.activeMarkerId = ''
      }
    }
  },
  mounted () {
    const { mapIns } = this.$refs.mortarMapRef.init()
    console.log('mounted')
    this.initBusList(mapIns).then(() => {
      // 初始化websocket推送
      console.log('初始化websocket推送')
      addMessageLisner(25, this.handlerBusMessage)
    })
  },
  beforeDestroy () {
    window.closeInfoWin2 = null
  },
  methods: {
    closeInfoWin2 (busID) {
      const marker = this.markersMap.get(busID)
      if (marker) {
        marker.closeInfoWindow()
      }
      this.currentShowBusId = -1
    },
    tabClick (tab) {
    },
    // 处理推送来的数据
    handlerBusMessage (busList) {
      console.log('处理推送数据', busList)
      try {
        busList.forEach((busData) => {
          const newNode = formatWebsocketData(busData)
          this.updateMarkerAndList(newNode)
        })
      } catch (error) {
        console.error(error)
      }
    },
    // 要更新哪些数据:
    // 1. marker在地图中的经纬度，marker.properties中的经纬度，图标，车头方向
    // 2. 更新列表中的数据，若经纬度发生变化时，置空地址(更新了经纬度需要重新获取地址)
    updateMarkerAndList (busData) {
      const marker = this.markersMap.get(busData.busID) // busData.vehiId
      const layerType = this.$refs.mortarMapRef.layerType
      const tableRow = this.tableDataMap.get(busData.busID)
      if (tableRow) {
        /* ws 余量  本地数据 maxWeight */
        if (busData.weight == '' || busData.weight == null) {
          busData.weight = 0
        }
        if (tableRow.maxWeight == '' || tableRow.maxWeight == null || tableRow.maxWeight === 0) {
          tableRow.maxWeight = 40
        }
        let persent = parseFloat(busData.weight) * 100 / parseFloat(tableRow.maxWeight)
        persent = persent.toFixed(0)
        if (busData.weight < 0) {
          console.log('负数余量', busData)
        }
        // console.log(persent, busData.weight, tableRow.maxWeight)
        if (persent > 100) {
          persent = 100
        }
        busData.persent = persent
        busData.bigIcon = getPotIcon({
          isOnline: true,
          persent,
          type: 'big'
        })
        busData.markerIcon = getPotIcon({
          isOnline: true,
          persent,
          type: 'another'
        })
        //  console.log(123, tableRow, busData)
        Object.assign(tableRow, busData)
        /* console.log(1, tableRow) */
        if (!marker) return
        // 更新在地图中的位置
        const position = formatLngLat(tableRow.lngLat, 'gps', layerType)
        marker.setCoordinates(position)

        // 更新properties中的wgs84经纬度
        const properties = marker.getProperties()
        properties.lng = busData.lng
        properties.lat = busData.lat
        marker.setProperties(properties)
        // 更新图标， 车头方向
        marker.updateSymbol([{
          markerFile: busData.markerIcon
          // markerRotation: busData.angle
        }])

        // 地图以marker为中心
        if (busData.busID == this.activeMarkerId) {
          marker.getMap().panTo(position)
        }

        // 更新列表数据
        if (tableRow.lngLatStr !== busData.lngLatStr) tableRow.address = ''
        Object.assign(tableRow, busData)
      }
    },
    async refreshBusList () {
      this.loading = true
      const res = await getPotList(undefined, true, false)
      this.loading = false
      if (res.code == 200) {
        const busList = res.data.map((item) => {
          const [lng, lat] = item.lngLat ? (item.lngLat.split(',')) : [0, 0]
          return {
            // Angle: item.angle,
            busID: item.busID,
            DimX: lng * 1000000, // 从这个接口获取的要乘1000000，因为ws处理时需要除以1000000
            DimY: lat * 1000000,
            lastFixDatTim: item.lastFixDatTim,
            Mileage: item.mileage,
            Speed: item.speed
          }
        })
        this.handlerBusMessage(busList)
      } else {
        this.$message.error(res.msg)
      }
    },
    outOfChina (lng, lat) {
      return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55)
    },
    // 初始化busList，创建并存储marker数据，table数据
    async initBusList (mapIns) {
      this.loading = true
      const res = await getPotList({})
      this.loading = false
      if (res.code === 200) {
        const markers = this.markers
        const markersMap = this.markersMap
        const tableData = this.tableData
        const tableDataMap = this.tableDataMap
        console.log('shajiang列表数据', res.data)
        res.data.forEach((item) => {
          const busNode = busDataFormat(item)
          const markerIns = this.getMarkerIns(busNode)
          this.initMarkerWindow(markerIns, item)
          markers.push(markerIns)
          markersMap.set(busNode.busID, markerIns)
          tableData.push(busNode)
          tableDataMap.set(busNode.busID, busNode)
        })
        // 创建markers图层添加到地图中
        this.createMarkersLayerToMap(markers, mapIns)
        this.refreshMarkerWindow()

        return res
      } else {
        this.$message.error(res.msg)
      }
    },
    initMarkerWindow (markerIns, node) {
      const infoWindow = new maptalks.ui.InfoWindow({
        autoPan: false,
        autoOpenOn: 'click', // set to null if not to open window when clicking on map
        single: true,
        custom: true,
        width: 300,
        height: 300,
        dx: this.getTextWidth(node.potNo) / 2 - 1,
        dy: -14,
        content: this.getMarkerWindowContent(node)
      })
      markerIns.setInfoWindow(infoWindow)
      markerIns.on('click', () => {
        this.activeMarkerId = markerIns.getId()
        const row = this.tableDataMap.get(markerIns.getId())
        this.$refs.carTable.activeRow(row)
        markerIns.bringToFront()
        markerIns.getMap().panTo(markerIns.getCoordinates())
      })
    },
    // 创建marker图层添加到地图实例中
    createMarkersLayerToMap (markers, mapIns) {
      const clusterLayer = this.clusterLayer = new ClusterLayer('cluster', markers, {
        noClusterWithOneMarker: true,
        maxClusterZoom: 17, // 聚合
        maxClusterRadius: 65,
        zIndex: 9,
        symbol: {
          markerType: 'ellipse',
          markerFill: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 'rgb(135, 196, 240)'],
              [9, '#1bbc9b'],
              [99, 'rgb(216, 115, 149)']
            ]
          },
          markerFillOpacity: 0.8,
          markerLineOpacity: 1,
          markerLineWidth: 3,
          markerLineColor: '#fff',
          markerWidth: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 30],
              [9, 40],
              [99, 55]
            ]
          },
          markerHeight: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 30],
              [9, 40],
              [99, 55]
            ]
          }
        },
        drawClusterText: true,
        geometryEvents: true,
        single: true,
        animation: false
      })

      this.$nextTick(() => {
        mapIns.addLayer(clusterLayer)
      })
    },
    // 获取marker实例
    getMarkerIns (node) {
      const lngLat = formatLngLat(
        [node.lng, node.lat],
        'gps',
        this.$refs.mortarMapRef.layerType
      )

      return new maptalks.Marker(lngLat, {
        id: node.busID, // 唯一标识 vehiId
        properties: {
          name: node.potNo,
          lng: node.lng,
          lat: node.lat
        },
        symbol: [
          {
            markerFile: node.markerIcon, // marker 图标
            markerWidth: 32,
            markerHeight: 48,
            markerHorizontalAlignment: 'middle',
            markerVerticalAlignment: 'top',
            markerDy: 2
            // markerRotation: node.angle
          },
          {
            textFaceName: 'sans-serif',
            textName: '{name}',
            textSize: 12,
            textDx: 12,
            textDy: -32,
            textFill: '#000000',
            textHorizontalAlignment: 'right',
            textVerticalAlignment: 'top',
            markerType: 'square',
            markerFill: 'rgb(255,255,255)',
            markerFillOpacity: 0.9,
            markerLineColor: '#ff0000',
            markerLineWidth: 1,
            markerWidth: this.getTextWidth(node.potNo) + 5,
            markerHeight: 17,
            markerDx: 10,
            markerDy: -30,
            markerRotation: 0,
            markerHorizontalAlignment: 'right',
            markerVerticalAlignment: 'top'
          }
        ]
      })
    },
    // 激活筛选的树节点：
    // 1. 树移动到该节点；2. 出发列表该行的双击事件
    activedNode (item) {
      this.dblClickNode(item)
    },
    // 隐藏未选中的车辆，展示选中的车辆
    checkedNode (checkedIds) {
      const markersMap = this.markersMap
      for (const [key, markerIns] of markersMap) {
        if (checkedIds.includes(key)) {
          markerIns.show()
        } else {
          markerIns.hide()
        }
      }
      const tableData = this.tableData.filter(item => {
        return checkedIds.includes(item.busID)
      })
      this.tableData = tableData
    },

    // 双击树节点，移动并高亮表格行，并执行表格行的双击事件
    dblClickNode (node) {
      if (node && node.potEnum == 'DEV') {
        const row = this.tableDataMap.get(node.busID)
        if (row) {
          this.$refs.carTable.activeRow(row)
          this.dblclickRow({ row })
        }
      }
    },
    // 获取车牌生成图片后的宽度
    getTextWidth (text, font = 'normal 12px sans-serif') {
      const canvas =
        this.textCanvas || (this.textCanvas = document.createElement('canvas'))
      const context = canvas.getContext('2d')
      context.font = font
      return context.measureText(text).width
    },
    // 获取marker窗口内容
    getMarkerWindowContent (node) {
      const downIcon = require('@/assets/imgs/mortar/icon/infowindow-arrow_down.png')
      const closeIcon = require('@/assets/imgs/mortar/icon/infownd-close-hover.png')
      // 详情显示内容
      const html = '<div style="min-width: 300px;background: rgba(255, 255, 255, 0.95);border: 1px solid #b4b3b3;border-radius: 3px;padding: 5px" >' +
                    '<em style="display: block;width: 17px; height: 10px;background: url(' + downIcon + ') no-repeat;position: absolute;left: 50%;margin-left: -5px;bottom: -9px;" ></em> ' +
                    '<img src="' + closeIcon + '" style="position: absolute;top: 8px;right: 10px;cursor: pointer" onclick="closeInfoWin2(' + node.busID + ')"' +
                    '<div style="display:flex;flex-direction: column;align-items: center;justify-content: center;background: white ">' +
                    '<div style="display: flex;flex-direction: row;width:100%;padding-right: 10px">' +
                    '<div style="width:100px;display: flex;justify-content:center;flex-direction: column;align-items: center;border-right: #D3D3D3 solid 1px">' +
                    '<div style="display: flex;flex-direction: column;align-items: center;justify-content: center" style="width: 65px;height: 95px">' +
                    '<img src="' + node.bigIcon + '" >' +
                    '</div>' +
                    '<div style="width: 65px;text-align: center">' + node.persent + '%</div>' +
                    '</div>' +
                    '<div style="flex: 2;display: flex;flex-direction: column;margin-left: 10px;">' +
                    '<div style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left" >罐号:' + node.potNo + '</div>' +
      // '<div style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left" >罐名称:' + node.busLicPlate + '</div>' +
                    '<div  style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left">砂浆类型:' + node.potType + '</div>' +
                    '<div  style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left">余量:' + node.weight + '(吨)</div>' +
                    '<div  style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left">时间:' + node.lastFixDatTim + '</div>' +
                    '<div  style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left">所在工地:' + node.projectName + '</div>' +
                    '<div  style="white-space: nowrap;font-size: 12px;display: flex;justify-content: left">所属客户:' + node.customerName + '</div>' +
                    '</div>' +
                    '</div>'
      return html
    },
    refreshMarkerWindow () {
      const timer = setInterval(() => {
        if (this.activeMarkerId) {
          const markerIns = this.markersMap.get(this.activeMarkerId)
          const node = this.tableDataMap.get(this.activeMarkerId)
          markerIns.getInfoWindow().setContent(this.getMarkerWindowContent(node))
        }
      }, 2000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },
    handlerMapLayerChange ({ mapIns, layerType }) {
      this.markers.forEach((marker) => {
        const properties = marker.getProperties()
        const position = formatLngLat(
          [properties.lng, properties.lat],
          'gps',
          layerType
        )
        marker.setCoordinates(position)
      })
    },
    // 车辆数据展示
    updateRowAddress ({ busID, address }) {
      const row = this.tableDataMap.get(busID)
      row.address = address
    },
    // 车辆数据展示
    // 点击表格行，移动至marker 并 展开信息面板
    // 双击树或表格或marker，
    dblclickRow ({ row }) {
      const marker = this.markersMap.get(row.busID)
      // const node = this.tableDataMap.get(row.vehiId)
      const center = marker.getCoordinates()
      if (marker) {
        this.activeMarkerId = marker.getId()
        marker.bringToFront()
        marker.getMap().animateTo({
          center,
          zoom: 18
        }, {
          duration: 400
        })
        marker.flash(200, 3, () => {
          marker.openInfoWindow()
        })
      }
    },
    showMortarInfo (mortar) {
      this.currentMortarInfo = mortar
      this.mortarInfoDialog = true
    }
  }
}
</script>

<style lang="scss">
.tab-content {
  height: calc(100% - 37px);
  margin-left: -1px;
}
.toolsBar {
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  padding-left: 5px
}
.el-tabs__content{
  height:100%;
}
.el-tabs--border-card {
  border: 0 !important;
}

.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
  height: 100%;
}
/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar{
  width:10px;
  height:10px;
  height:10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color:#ffffff;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytble-scrollbar ::-webkit-scrollbar-thumb {
  background-color:#bfbfbf;
  border-radius:5px;
  border:1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}

</style>
