<template>
  <div style="display: flex;overflow-y: auto;height: 100%;max-height: 100%;  padding-left: 10px;padding-right: 10px;width: calc(100% - 20px);flex-wrap: wrap;justify-content: space-between">
    <div v-for="project in projectMortarData" :key="project.projectID" :index="project.projectID" :style="{width:rowWidth}">
      <div style="margin-bottom: 5px;margin-top: 5px;display: flex;flex-direction: column;border:  1px solid;background: #eeeeee" :style="{borderColor:$store.state.settings.theme}">
        <div style="display: flex;height: 35px;align-items: center;padding-left: 10px;filter:opacity(0.7)" :style="{backgroundColor:$store.state.settings.theme}">
          <div style="flex: 1;display: flex">
            <span style="font-weight: bold;font-size: 14px;color: white">{{project.projectName}} </span>
          </div>
          <el-button style="margin-right: 5px" size="mini" type="primary" round @click="showProjectDialog(project)">更多({{project.children.length}}) </el-button>
        </div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;font-size: 13px;height: 135px;overflow-y: auto">
          <div style="margin: 5px;padding: 5px 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;" :index="mortar.busID" v-for="mortar in project.children"
               :key="mortar.busID" @click="showMortarInfoInparent(mortar)">
            <div>
              {{mortar.persent}}%
            </div>
            <div :style="{'background-image':'url('+mortar.bigIcon+')'}" style="background-size: cover;height: 77px;width: 52px;display: flex;flex-direction: column;justify-content: flex-end; align-items: center;cursor: pointer ">
              <div style="margin-bottom: 30px">
                {{mortar.weight}}
              </div>
            </div>
            <div>
              {{mortar.potNo}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="cproject.projectName"
      :visible.sync="projectDialog"
      :append-to-body="true"
      v-dialogDrag
      width="750px">
      <div style="display: flex;flex-direction: row;flex-wrap: wrap;font-size: 13px;border-bottom: 1px solid #D3D3D3">
        <div style="margin: 5px;padding: 5px 16px;display: flex;flex-direction: column;align-items: center;justify-content: center;" :index="mortar.busID" v-for="mortar in cproject.children"
             :key="mortar.busID" @click="showMortarInfoInparent(mortar)">
          <div>
            {{mortar.persent}}%
          </div>
          <div :style="{backgroundImage:'url('+mortar.bigIcon+')'}" style="background-size: cover;height: 95px;width: 65px;display: flex;flex-direction: column;justify-content: flex-end; align-items: center;cursor: pointer ">
            <div style="margin-bottom: 30px">
              {{mortar.weight}}
            </div>
          </div>
          <div>
            {{mortar.potNo}}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="projectDialog = false" size="mini">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPotTree0 } from '@/apis/monitor/mortarMonitor'
import XEUtils from 'xe-utils'
import { addMessageLisner } from '@/utils/websocket'
import { formatWebsocketData, getPotIcon } from './util'

export default {
  name: 'MortarByProject',
  props: {
    showMortarInfo: { type: Function, default: null }
  },
  data () {
    return {
      projectMortarData: [],
      mortarMap: new Map(),
      rowWidth: 'calc(33.3% - 5px)',
      cproject: {},
      projectDialog: false,
      sortType: 1
    }
  },
  created () {
    // this.$websocket.addMessageLisner(24, this.onDriverMessage)
  },
  beforeDestroy () {
    // this.$websocket.removeMessageLisner(24, this.onDriverMessage)
  },
  async mounted () {
    await this.getPotData()
    // 接口请求回来3s之后 开始推送
    setTimeout(() => {
      addMessageLisner(25, this.onWsMessage)
    }, 3000)
  },
  methods: {
    getBgPicture (iconUrl) {
      const iconSrc = require(iconUrl)
      return iconSrc
    },
    onWsMessage (data) {
      data.forEach(item => {
        const newNode = formatWebsocketData(item)
        this.onDriverMessage(newNode)
      })
    },
    showProjectDialog (project) {
      this.cproject = project
      this.projectDialog = true
    },
    rowProSizeChange (val) {
      if (val === 1) {
        this.rowWidth = 'calc(100% - 5px)'
      }
      if (val === 2) {
        this.rowWidth = 'calc(50% - 5px)'
      }
      if (val === 3) {
        this.rowWidth = 'calc(33.3% - 5px)'
      }
      if (val === 4) {
        this.rowWidth = 'calc(25% - 5px)'
      }
      if (val === 5) {
        this.rowWidth = 'calc(20% - 5px)'
      }
      if (val === 6) {
        this.rowWidth = 'calc(16.6% - 5px)'
      }
    },
    sortTypeChange (val) {
      this.sortType = val
      if (val === 1) {
        this.projectMortarData.sort(function (a, b) {
          return b.children.length - a.children.length
        })
      } else {
        this.projectMortarData.sort(function (a, b) {
          return a.projectName.localeCompare(b.projectName, 'zh-CN')
        })
      }
    },
    async getPotData (cancel, cache) {
      getPotTree0({ key: 'DEV', parentKey: 'PROJECT' }, cancel, cache).then((res) => {
        if (res.code === 200) {
          let projectMortarData = XEUtils.map(res.data.rtmTreeList.treeList, (item) => item.detail)
          projectMortarData = XEUtils.groupBy(projectMortarData, 'projectID')
          // 转数组
          const arrProj = []
          XEUtils.objectEach(projectMortarData, (value, key) => {
            arrProj.push({
              projectID: key,
              projectName: value[0].projectName || '未知工地',
              children: value
            })
          })
          projectMortarData = arrProj
          projectMortarData.forEach((item) => {
            if (item.children) {
              item.children.forEach(mortar => {
                if (mortar.weight == '' || mortar.weight == null) {
                  mortar.weight = 0
                }
                mortar.maxWeight = mortar.maxWeig
                if (mortar.maxWeight == 0) {
                  mortar.maxWeight = 40
                }
                let persent = mortar.weight * 100 / mortar.maxWeight
                persent = persent.toFixed(0)
                if (persent > 100) {
                  persent = 100
                }
                let persentImg = persent
                if (persentImg < 2) {
                  persentImg = 2
                }
                if (persentImg % 2 === 1) {
                  persentImg = persentImg - 1
                }
                mortar.bigIcon = require('@/assets/imgs/mortar/big/gray/' + persentImg + '.png')
                mortar.persent = persent
                mortar.isOnlineStr = '离线'
                this.mortarMap.set(mortar.busID, mortar)
              })
            }
          })
          projectMortarData.sort(function (a, b) {
            return b.children.length - a.children.length
          })
          this.projectMortarData = projectMortarData
        }
      })
    },
    onDriverMessage (msg) {
      const mortar = this.mortarMap.get(msg.busID)
      if (mortar) {
        /*  if (mortar.weight == '' || mortar.weight == null) {
          mortar.weight = 0
        }
        if (mortar.maxWeight == '' || mortar.maxWeight == null || mortar.maxWeight == 0) {
          mortar.maxWeight = 40
        }
        let persent = parseFloat(mortar.weight) * 100 / parseFloat(mortar.maxWeight)
        persent = persent.toFixed(2)
        if (persent > 100) {
          persent = 100
        }
        let persentImg = persent
        if (persentImg < 2) {
          persentImg = 2
        }
        if (persentImg % 2 === 1) {
          persentImg = persentImg - 1
        }
        mortar.bigIcon = require('@/assets/imgs/mortar/big/blue/' + persentImg + '.png') */
        /* ws 余量  本地数据 maxWeight */
        if (msg.weight == '' || msg.weight == null) {
          msg.weight = 0
        }
        let persent = parseFloat(msg.weight) * 100 / parseFloat(mortar.maxWeight)
        persent = persent.toFixed(0)
        if (persent > 100) {
          persent = 100
        }
        mortar.bigIcon = getPotIcon({
          isOnline: true,
          persent,
          type: 'big'
        })
        mortar.markerIcon = getPotIcon({
          isOnline: true,
          persent,
          type: 'another'
        })
        mortar.persent = persent
        Object.assign(mortar, msg)
      }
    },
    /*  onMortarOffline (msg) {
      const mortar = this.mortarMap.get(msg.busID)
      if (mortar) {
        let persentImg = mortar.persent
        if (persentImg < 2) {
          persentImg = 2
        }
        if (persentImg % 2 === 1) {
          persentImg = persentImg - 1
        }
        mortar.bigIcon = '/static/images/mortar/big/gray/' + persentImg + '.png'
        mortar.isOnlineStr = '离线'
      }
    }, */
    showMortarInfoInparent (mortar) {
      if (this.showMortarInfo) {
        this.showMortarInfo(mortar)
      }
    }
  }
}
</script>
